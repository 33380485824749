import './App.css';
import LandingPage from './Pages/LandingPage/LandingPage';

function App() {
  return (
    <LandingPage />
  );
}

export default App;
